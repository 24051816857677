import React, { useEffect, useState } from "react";
import { SearchBar } from "@components/SearchBar/SearchBar";
import FlexContainer from "@components/FlexContainer/FlexContainer";
import Link from "next/link";
// import { fetcher1819 } from "@utils/Fetcher1819";
import Head from "next/head";
import { THEME_COLOR, WEBPAGE_TITLE } from "@utils/Constants";
import LaufidLogo from "@components/Icons/LaufidLogo/LaufidLogo";
import { PodcastSection } from "@components/PodcastSection/PodcastSection";
import { TryFreeSection } from "@components/TryFreeSection/TryFreeSection";
import CompaniesSection from "@components/CompaniesSection/CompaniesSection";
import { ContentContainer } from "@components/ContentContainer/ContentContainer";
import { fetcher1819 } from "@utils/Fetcher1819";

const FrontPage = () => {
    const [ad, setAd] = useState();

    useEffect(() => {
        (async () => {
            var fetchedAd = await fetcher1819("auglysing/1");
            if (fetchedAd.length > 0) {
                setAd(fetchedAd[0]);
            }
        })();
    }, []);

    return (
        <>
            <Head>
                <title key="site_title">
                    {WEBPAGE_TITLE + " - Græn upplýsingaveita"}
                </title>
                <meta
                    property="og:title"
                    content={`${WEBPAGE_TITLE} - Græn upplýsingaveita`}
                    key="og:title"
                />
            </Head>
            <FlexContainer column justifyContent="center" alignItems="center">
                <div style={{ paddingTop: "5vh" }}></div>
                <Link href="/">
                    <LaufidLogo
                        alt="Laufið logo"
                        style={{
                            margin: "50px 0px",
                            height: "17vh",
                            maxHeight: "60px",
                            maxWidth: "80vw",
                            color: THEME_COLOR,
                        }}
                    />
                </Link>
                <div style={{ paddingTop: "5vh" }}></div>
                <ContentContainer
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <SearchBar style={{ maxWidth: 700 }} />
                </ContentContainer>
                <div style={{ paddingTop: "10vh" }}></div>
                <FlexContainer
                    justifyContent="center"
                    style={{ aspectRatio: "998/123" }}
                >
                    <Link
                        href={`https://old.1819.is/linkto/${ad?.ad_id}/1?url=${ad?.linkto}`}
                        className="d-flex justify-content-center"
                    >
                        <img
                            aria-label="Auglýsing"
                            style={{
                                maxHeight: 126,
                                maxWidth: 1018,
                                width: "98%",
                                justifyContent: "center",
                            }}
                            src={ad?.url}
                        />
                    </Link>
                </FlexContainer>
                <div style={{ paddingTop: "7vh" }}></div>
                <CompaniesSection />
                <div style={{ paddingTop: "10vh" }}></div>
                {/* <TryFreeSection /> */}
                {/* <PodcastSection /> */}
                {/* <iframe
                    className={styles.introductionVideo}
                    src="https://www.youtube.com/embed/aBnSwrICeAg"
                    title="Stutt kynning á Laufinu"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                ></iframe> */}
                {/* <div style={{ padding: "5vh 0px" }}></div> */}
            </FlexContainer>
        </>
    );
};

export default FrontPage;
