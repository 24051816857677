import React from "react";
import { useCompanyLeaves } from "@hooks/useCompany";
import styles from "./CompanyCard.module.scss";
import Link from "next/link";
import IndustryIcon from "@components/Icons/IndustryIcon";
import FlexContainer from "@components/FlexContainer/FlexContainer";
import { Leaf } from "@laufid/components";
import { Tooltip as ReactTooltip } from "react-tooltip";
import fullCopy from "@helpers/full_copy";
import combineLeaves from "@helpers/combine_leaves";
import { motion } from "framer-motion";
import { THEME_COLOR } from "@utils/Constants";
import GreenStepsIndicator from "@components/GreenStepsIndicator/GreenStepsIndicator";

export const CompanyCard = ({ company, leaves }) => {
    const companyLeaves2 = useCompanyLeaves(company?.id);
    var leavesResult = null;
    var companyLeaves = companyLeaves2?.data?.results;
    var allLeaves = leaves?.data?.results;

    if (companyLeaves && allLeaves) {
        var bla = fullCopy(companyLeaves);
        var bla2 = fullCopy(allLeaves);
        leavesResult = combineLeaves(bla, bla2);
    }
    return (
        <>
            {leavesResult?.length > 0 && (
                <ReactTooltip
                    place="bottom"
                    effect="solid"
                    id="my-tooltip"
                    style={{
                        backgroundColor: THEME_COLOR,
                        borderRadius: 900,
                        color: "white",
                        padding: "5px 15px",
                        boxShadow: "0 0 5px #0000000a",
                        zIndex: 100,
                    }}
                    backgroundColor={THEME_COLOR}
                />
            )}
            <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className={styles.container}
            >
                <Link href={`/info/${company?.registration_id}`}>
                    <FlexContainer
                        alignItems="center"
                        column
                        style={{ minHeight: 100 }}
                    >
                        {company ? (
                            company?.logo ? (
                                <img src={company?.logo} />
                            ) : (
                                <IndustryIcon
                                    style={{
                                        height: 70,
                                        width: 70,
                                        padding: 2,
                                        objectFit: "contain",
                                        color: "gray",
                                    }}
                                />
                            )
                        ) : (
                            <>
                                <div className={styles.imagePlaceholder} />
                            </>
                        )}

                        <FlexContainer column>
                            {company ? (
                                <div
                                    className={styles.companyNameContainer}
                                    title={company?.name}
                                >
                                    <h5>{company?.name}</h5>
                                </div>
                            ) : (
                                <div
                                    className={
                                        styles.companyNamePlaceholderContainer
                                    }
                                >
                                    <div
                                        className={
                                            styles.companyNamePlaceholder
                                        }
                                    />
                                    {/* <div className={styles.companyNamePlaceholder} /> */}
                                </div>
                            )}

                            <div className={styles.leafContainer}>
                                {!leavesResult &&
                                    [0, 0, 0, 0, 0].map((item, index) => (
                                        <Leaf
                                            className={styles.laufidLeaf}
                                            key={index}
                                            percentage={0}
                                        />
                                    ))}

                                {leavesResult?.map((leaf) => {
                                    return (
                                        <Leaf
                                            className={styles.laufidLeaf}
                                            isActive={
                                                leaf.fulfilled && leaf.approved
                                            }
                                            key={leaf.id}
                                            title={leaf.name}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={leaf.name}
                                            percentage={
                                                leaf?.fulfilledCount /
                                                leaf?.leaf_items?.length
                                            }
                                        />
                                    );
                                })}
                            </div>
                            <GreenStepsIndicator
                                style={{ margin: "15px 15px -5px 15px" }}
                                percentage={
                                    company?.green_steps_percentage / 100.0
                                }
                            />
                        </FlexContainer>
                    </FlexContainer>
                </Link>
            </motion.div>
        </>
    );
};
