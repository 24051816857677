import * as React from "react";

const PlayIcon = (props) => (
    <svg
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M29.479.781A28.923 28.923 0 1 0 56.2 18.635 28.914 28.914 0 0 0 29.48.78Zm-5.784 41.937V16.69l17.353 13.015-17.353 13.014Z"
            fill="#fff"
        />
    </svg>
);

export default PlayIcon;
