import React from "react";
import { useRouter } from "next/router";
import ReactGA_UA from "react-ga";
import ReactGA_4 from "react-ga4";
import dynamic from "next/dynamic";
import FrontPage from "@components/Pages/FrontPage/FrontPage";

const Page = dynamic(() => import("@components/Layout/page"));
const SearchResults = dynamic(() =>
    import("@components/Pages/SearchResultsPage/SearchResultsPage")
);

function laufidPage() {
    // Translation
    const router = useRouter();
    React.useEffect(() => {
        ReactGA_UA.pageview(router.asPath);
        ReactGA_4.send({ hitType: "pageview", page: router.asPath });
    }, [router.query.q]);
    return (
        <>
            {router.query.q ? (
                <Page fluid showsSearchBar>
                    <SearchResults query={router.query.q} />
                </Page>
            ) : (
                <>
                    <Page fluid>
                        <FrontPage />
                    </Page>
                </>
            )}
        </>
    );
}

export default laufidPage;
