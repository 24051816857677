export default function combineLeaves(companyLeaves, leafList) {
    if (!leafList || !companyLeaves) {
        return null;
    }

    var result = leafList;

    companyLeaves?.forEach((element) => {
        element.fulfilled = false;
        leafList?.forEach((leaf) => {
            if (leaf.id === element.leaf.id) {
                var fulfilledItemIds = element.leaf_items?.split(",");
                var fulfilledCount = 0;
                fulfilledItemIds?.forEach((item) => {
                    leaf.leaf_items?.forEach((leafItem) => {
                        if (leafItem.id == item) {
                            leafItem.fulfilled = true;
                            fulfilledCount = fulfilledCount + 1;
                        }
                    });
                });
                leaf.fulfilledCount = fulfilledCount;
                leaf.approved = element.approved;
                if (leaf.fulfilledCount == leaf.leaf_items?.length) {
                    leaf.fulfilled = true;
                }
                return;
            }
        });
    });
    result.sort((a, b) => {
        var aSort = a.fulfilledCount / a.leaf_items.length || 0;
        var bSort = b.fulfilledCount / b.leaf_items.length || 0;
        return bSort - aSort;
    });
    return result;
}
