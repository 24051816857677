import fetcher from "@utils/Fetcher";
import useSWR from "swr";

export const useCompany = (companyID, config) => {
    return useSWR(companyID ? "company/" + companyID : null, fetcher, config);
};

export const useCompanyPluses = (companyID, config) => {
    return useSWR(
        companyID ? "company_plus/by_company/" + companyID : null,
        fetcher,
        config
    );
};

export const useCompanyStories = (companyID, config) => {
    return useSWR(
        companyID ? "company_story/by_company/" + companyID : null,
        fetcher,
        config
    );
};

export const useCompanyCategories = (config) => {
    return useSWR("company_category", fetcher, config);
};

export const useCompanyLeaves = (companyID, config) => {
    return useSWR(
        companyID ? "company_leaf/" + companyID : null,
        fetcher,
        config
    );
};

export const useCompanyGoals = (companyID, config) => {
    return useSWR(
        companyID ? "company_goal/by_company/" + companyID : null,
        fetcher,
        config
    );
};

export const useCompanyCarbonGraph = (companyID, config) => {
    return useSWR(
        companyID ? "company_graph/" + companyID : null,
        fetcher,
        config
    );
};

export const useCompanyDetails = (companyID, config) => {
    return useSWR(
        companyID ? "company/company_details/" + companyID : null,
        fetcher,
        config
    );
};

export const useCompanyCertificates = (companyID, config) => {
    return useSWR(
        companyID ? "company_certificate/by_company/" + companyID : null,
        fetcher,
        config
    );
};

export const useCompanyEnvironmentalPolicy = (companyID, config) => {
    return useSWR(
        companyID
            ? "company_environmental_policy/by_company/" + companyID
            : null,
        fetcher,
        config
    );
};

export const useCompanyPopular = (config) => {
    return useSWR("company/get_popular", fetcher, config);
};

export const useGreenSteps = (company_id, config) => {
    if (!company_id) return useSWR(null, fetcher, config);
    return useSWR(
        `green_step_category?company_id=${company_id}`,
        fetcher,
        config
    );
};
