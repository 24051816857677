import { CompanyCard } from "@components/CompanyCard/CompanyCard";
import { useCompanyPopular } from "@hooks/useCompany";
import { useLeaves } from "@hooks/useLeaves";
import React from "react";
import styles from "./ActiveCompaniesList.module.scss";
import { Title } from "@components/ui";
import { ContentContainer } from "@components/ContentContainer/ContentContainer";

export default function ActiveCompaniesList() {
    const leaves = useLeaves();
    const { data } = useCompanyPopular();
    return (
        <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
            <Title
                size={3}
                style={{ flexShrink: 1, flex: 1, marginBottom: 20 }}
            >
                Brot af viðskiptavinum Laufsins
            </Title>
            <div className={styles.container}>
                {!data
                    ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map(
                          (item, index) => {
                              return <CompanyCard key={index} />;
                          }
                      )
                    : data?.results?.map((company) => {
                          return (
                              <CompanyCard
                                  key={company.id}
                                  company={company}
                                  leaves={leaves}
                              />
                          );
                      })}
            </div>
        </ContentContainer>
    );
}
