import { ContentContainer } from "@components/ContentContainer/ContentContainer";
import PlayIcon from "@components/Icons/PlayIcon";
import FlexContainer from "@components/FlexContainer/FlexContainer";
import { WaveyContainer } from "@components/WaveyContainer/WaveyContainer";
import { Button } from "@laufid/components";
import React from "react";
import styles from "./PodcastSection.module.scss";

const EPISODES = [
    {
        link: "https://open.spotify.com/episode/23k8xYpMkUxeg83MKuFyfZ?si=64bac21f18a24d58",
        title: "Heimsmarkmiðin eru grunnurinn",
        description: "Iðan fræðslusetur",
    },
    {
        link: "https://open.spotify.com/episode/1mFMvulowhNAo0y5ySNXgU?si=8edf0080151e4130",
        title: "Fjármál og sjálfbærni",
        description:
            "Sjálfbærnistjóri Landsbankans og sérfræðingur á skrifstofu opinberra fjármála hjá fjármála- og efnahags-ráðuneytinu",
    },
    {
        link: "https://open.spotify.com/episode/6jK1YLBmMTr8jaZuYitJfP?si=7dc0e72e6fce46d6",
        title: "Aðlögun að loftslagsbreytingum",
        description:
            "Sérfræðingur á skrifstofu loftslagsmála hjá umhverfis- orku- og loftslagsmála",
    },
];

export const PodcastSection = () => {
    return (
        <WaveyContainer
            color="#095EA6"
            style={{
                paddingTop: "clamp(20px, 10vw, 40px)",
                paddingBottom: "clamp(20px, 10vw, 40px)",
            }}
        >
            <ContentContainer>
                <FlexContainer
                    className={styles.container}
                    style={{ gap: 100 }}
                >
                    <FlexContainer
                        style={{
                            flexDirection: "column",
                        }}
                    >
                        <h2
                            style={{
                                lineHeight: "67px",
                                fontWeight: 400,
                                color: "white",
                                marginBottom: "28px",
                            }}
                        >
                            Hlaðvarp Laufsins
                        </h2>

                        <img
                            src="/img/podcast.png"
                            style={{ height: 300, width: 300 }}
                        />
                        <Button
                            size={"medium"}
                            style={{
                                fontSize: "20px",
                                backgroundColor: "white",
                                color: "#095EA6",
                                padding: "5px 35px",
                                marginTop: 34,
                                alignSelf: "flex-start",
                            }}
                            href={
                                "https://open.spotify.com/show/32Nx41Hk4V4JYI8bnvTRYr"
                            }
                        >
                            Hlusta
                        </Button>
                    </FlexContainer>
                    <div>
                        <h4
                            style={{
                                fontWeight: 400,
                                lineHeight: "67px",
                                color: "white",
                                marginBottom: "28px",
                            }}
                        >
                            Nýjustu þættirnir
                        </h4>
                        {EPISODES.map((episode, index) => (
                            <Episode
                                key={index}
                                link={episode.link}
                                title={episode.title}
                                description={episode.description}
                            />
                        ))}
                    </div>
                </FlexContainer>
            </ContentContainer>
        </WaveyContainer>
    );
};

function Episode({ title, description, link }) {
    return (
        <a href={link}>
            <FlexContainer style={{ gap: 15, marginBottom: 20 }}>
                <span>
                    <PlayIcon style={{ height: 35, width: 35 }} />
                </span>
                <div style={{ flexShrink: 1 }}>
                    <h4
                        style={{
                            color: "white",
                            lineHeight: "36px",
                            fontWeight: 600,
                        }}
                    >
                        {title}
                    </h4>
                    <ul style={{ listStylePosition: "outside" }}>
                        <li
                            style={{
                                color: "white",
                                fontSize: 20,
                                lineHeight: "33px",
                            }}
                        >
                            {description}
                        </li>
                    </ul>
                </div>
            </FlexContainer>
        </a>
    );
}
