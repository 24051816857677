import { ContentContainer } from "@components/ContentContainer/ContentContainer";
import FlexContainer from "@components/FlexContainer/FlexContainer";
import { Button, Title } from "@laufid/components";
import { THEME_COLOR } from "@utils/Constants";
import React from "react";
import styles from "./TryFreeSection.module.scss";

export const TryFreeSection = () => {
    return (
        <ContentContainer style={{ marginTop: 100, marginBottom: 100 }}>
            <FlexContainer className={styles.container} style={{ gap: 100 }}>
                <div style={{ flex: 3 }}>
                    <Title
                        size={2}
                        style={{
                            color: THEME_COLOR,
                            fontWeight: 600,
                            marginBottom: 55,
                        }}
                    >
                        Við aðstoðum þitt fyrirtæki í ykkar sjálfbærni vegferð
                    </Title>
                    <ul
                        style={{
                            fontSize: 20,
                            marginBottom: 55,
                        }}
                    >
                        <li>Er þitt fyrirtæki með skýra umhverfisstefnu?</li>
                        <li>Er þitt fyrirtæki að flokka rétt?</li>
                        <li>Veistu ekki hvar þú átt að byrja?</li>
                    </ul>
                    <p
                        style={{
                            fontSize: 20,
                            lineHeight: "35px",
                            marginBottom: 30,
                        }}
                    >
                        Við getum aðstoðað ykkur með þetta og meira, og verið
                        ykkar stoð í umhverfis- og sjálfbærni vegferð þíns
                        fyrirtækis.
                    </p>
                    <p style={{ fontSize: 20, lineHeight: "35px" }}>
                        Við bjóðum fyrirtækjum upp á stafrænan vettvang sem
                        stuðlar að sjálfbærri þróun íslensks atvinnulífs.
                    </p>
                </div>
                <div
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        flex: 2,
                    }}
                >
                    <img
                        src="/img/admin.png"
                        className={styles.image}
                        style={{}}
                    />

                    <Button
                        size={"large"}
                        style={{ marginTop: "80px", display: "inline-block" }}
                        href="https://admin.laufid.is/registration"
                    >
                        Prófa frítt í mánuð
                    </Button>
                </div>
            </FlexContainer>
        </ContentContainer>
    );
};
