import ActiveCompaniesList from "@components/ActiveCompaniesList/ActiveCompaniesList";
import { WaveyContainer } from "@components/WaveyContainer/WaveyContainer";
import { Title } from "@laufid/components";
import React from "react";
import styles from "./CompaniesSection.module.scss";

export default function CompaniesSection() {
    return (
        <div className={styles.container}>
            <div className={styles.titles}>
                {/* <a>
                    <Title style={{ fontWeight: 400, color: "white" }}>
                        Sjá öll fyrirtæki sem eru í Laufinu
                    </Title>
                </a> */}
            </div>
            <ActiveCompaniesList />
        </div>
    );
}
