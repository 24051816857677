import { API_ENDPOINT, APP_KEY_LAUFID } from "./Constants";

export async function fetcher(url, method, body, token) {
    const api_endpoint = API_ENDPOINT;

    const headers = {
        "app-key": APP_KEY_LAUFID,
        accept: "application/json",
        "content-type": "application/json",
    };

    if (token) {
        headers.authorization = `Bearer ${token}`;
    }
    const result = await fetch(api_endpoint + url, {
        method: method,
        headers: headers,
        body: body,
    });

    if (result.ok) {
        return await result.json();
    } else {
        console.dir(result);
        console.dir(result.headers);
        console.dir(result.json());
        throw new Error("Something went wrong");
    }
}
export default fetcher;
