import * as React from "react";
const GreenStepIcon2 = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23 30"
        width={23}
        height={30}
        fill="none"
        {...props}
    >
        <path
            fill="#009448"
            d="M13.055 12.843a8.23 8.23 0 0 1-1.1 1.59 8.952 8.952 0 0 1-4.308 2.5 8.197 8.197 0 0 1-2.2.037 14.476 14.476 0 0 1-3.209-.578 7.423 7.423 0 0 1-1.62-.684 1.068 1.068 0 0 1-.553-1.336c.348-1.54 1.534-2.38 2.7-3.182A8.944 8.944 0 0 1 7.7 9.68c1.34-.046 2.683.004 4.016.15a.201.201 0 0 0 .025-.1c-.3-1.815.479-3.306 1.392-4.767a9.37 9.37 0 0 1 3.655-3.412c.472-.248.932-.521 1.385-.807A5.435 5.435 0 0 1 20.68.01a1.117 1.117 0 0 1 1.23.821 7.75 7.75 0 0 1-.236 4.872 10.592 10.592 0 0 1-3.52 4.711c-.505.45-1.06.841-1.652 1.168-.26.13-.093.248-.012.367a9.675 9.675 0 0 1 1.713 4.257c.108.787.154 1.58.137 2.374-.012.97.012 1.939-.007 2.9a17.282 17.282 0 0 1-1.477 6.457 2.318 2.318 0 0 1-2.352 1.516 1.163 1.163 0 0 1-1.087-.77 2.238 2.238 0 0 1 .162-2.2 19.664 19.664 0 0 0 2.054-4.922 9.542 9.542 0 0 0-.434-5.065c-.21-.566-.4-1.138-.607-1.7a4.652 4.652 0 0 0-1.537-1.953Z"
        />
    </svg>
);
export default GreenStepIcon2;
