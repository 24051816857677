import * as React from "react";

function IndustryIcon(props) {
    return (
        <svg
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M506.652 214.784a9.999 9.999 0 00-10.324.616l-90.852 62.568v-54.333a10.001 10.001 0 00-15.673-8.236l-90.823 62.565v-54.33a10.001 10.001 0 00-15.673-8.236l-90.823 62.565v-35.94c0-5.523-4.477-10-10-10h-18.601v-99.915c0-13.686-11.124-24.819-24.796-24.819h-32.859v-28.69c4.972.936 9.893 1.417 14.712 1.417 12.08 0 23.533-2.932 33.609-8.749 17.657-10.194 29.75-28.478 34.052-51.483a10 10 0 00-6.506-11.27c-22.098-7.787-43.994-6.458-61.656 3.739-9.986 5.766-18.188 14.125-24.21 24.461-6.022-10.34-14.223-18.701-24.208-24.466C54.369 2.055 32.481.73 10.39 8.515a10.001 10.001 0 00-6.506 11.27c4.302 23.011 16.394 41.297 34.047 51.489 10.07 5.813 21.514 8.742 33.588 8.742 4.818 0 9.738-.481 14.708-1.417v28.693h-32.83c-13.688 0-24.825 11.134-24.825 24.819v99.915H10c-5.523 0-10 4.477-10 10v256.468c0 5.523 4.477 10 10 10h492c5.523 0 10-4.477 10-10V223.636a10 10 0 00-5.348-8.852zM130.44 29.574c10.299-5.947 22.938-7.532 36.28-4.671-4.192 12.962-11.878 23.102-22.17 29.044-10.299 5.947-22.936 7.533-36.272 4.675 4.19-12.966 11.873-23.108 22.162-29.048zM47.931 53.953c-10.29-5.941-17.974-16.083-22.167-29.05 13.337-2.859 25.967-1.276 36.257 4.665 10.286 5.939 17.967 16.083 22.158 29.054-13.329 2.855-25.957 1.272-36.248-4.669zm5.466 73.339h85.69c2.6 0 4.796 2.207 4.796 4.819v22.728h-95.31v-22.728a4.827 4.827 0 014.824-4.819zm-4.824 47.547h95.311v57.187H48.573zm73.768 313.655H70.144V388.352c0-.126.124-.229.205-.229h51.759c.096 0 .233.119.233.229zm50.144 0h-30.144V388.352c0-11.154-9.077-20.229-20.233-20.229H70.349c-11.141 0-20.205 9.075-20.205 20.229v100.142H20V252.026h152.485zM492 438.623H384.478c-5.523 0-10 4.477-10 10s4.477 10 10 10H492v29.871H192.485v-29.871H294.65c5.523 0 10-4.477 10-10s-4.477-10-10-10H192.485V302.252l86.496-59.584v54.33a10.002 10.002 0 0015.673 8.236l90.823-62.565v54.33a10 10 0 0015.672 8.236L492 242.665z"
                fill="currentColor"
            />
            <path
                d="M275.544 338.626h-52.809c-5.523 0-10 4.477-10 10v52.818c0 5.523 4.477 10 10 10h52.809c5.523 0 10-4.477 10-10v-52.818c0-5.523-4.477-10-10-10zm-10 52.817h-32.809v-32.818h32.809zM368.632 338.626h-52.808c-5.523 0-10 4.477-10 10v52.818c0 5.523 4.477 10 10 10h52.808c5.523 0 10-4.477 10-10v-52.818c0-5.523-4.477-10-10-10zm-10 52.817h-32.808v-32.818h32.808zM398.912 401.443c0 5.523 4.477 10 10 10h52.808c5.523 0 10-4.477 10-10v-52.818c0-5.523-4.477-10-10-10h-52.808c-5.523 0-10 4.477-10 10zm20-42.817h32.808v32.818h-32.808zM339.578 438.623c-5.523 0-10 4.477-10 10s4.477 10 10 10h.028c5.523 0 9.986-4.477 9.986-10s-4.491-10-10.014-10z"
                fill="currentColor"
            />
        </svg>
    );
}

export default IndustryIcon;
