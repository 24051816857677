import React from "react";
import styles from "./GreenStepsIndicator.module.scss";
import GreenStepIcon2 from "@components/Icons/GreenStepIcon2";
import classNames from "classnames";

export default function GreenStepsIndicator({
    percentage,
    onClick,
    style,
    className,
}) {
    percentage = Math.round(percentage * 100);

    const ProgressBar = (
        <div
            className={classNames(styles.container, className)}
            style={style}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={`Græn skref: ${percentage || 0}%`}
        >
            <div className={styles.barContainer}>
                <div
                    className={styles.progressBar}
                    style={{
                        width: `${percentage || 0}%`,
                    }}
                />
            </div>
            <div>
                <GreenStepIcon2 className={styles.icon} />
            </div>
        </div>
    );

    if (onClick) {
        return (
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    onClick?.();
                }}
            >
                {ProgressBar}
            </a>
        );
    }
    return ProgressBar;
}
