import React from "react";
import { THEME_COLOR } from "@utils/Constants";
import { Wave } from "@components/Wave/Wave";

export const WaveyContainer = ({ children, color, style, ...props }) => {
    if (color === undefined || color === null) color = THEME_COLOR;
    return (
        <>
            <Wave placement="top" color={color} />
            <div
                style={{
                    marginTop: -1,
                    marginBottom: -1,
                    background: color,
                    minHeight: 200,
                    width: "100%",
                    ...style,
                }}
                {...props}
            >
                {children}
            </div>
            <Wave placement="bottom" color={color} />
        </>
    );
};
